// Components import
import React from "react"
import Navbar from "./navbar"
import Footer from "./footer"
import CTA from "./cta"

import "../../../../static/assets/scss/global.scss"

class Layout extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <Navbar />
        <div className={"contingent"}>
          {this.props.children}
          <CTA />
        </div>
        <Footer />
      </div>
    )
  }
}

export default Layout
