import React from "react"
import Layout from "./shared/layout/layout"
import "../../static/assets/scss/pages/page.scss"
import GLOBE from "./shared/components/globe"
import MAP from "./shared/components/globe-2"
import LayoutDark from "./shared/layout/layout-dark"
import SignUpFormComponent from "./shared/components/sign-up-form.component"
import { Helmet } from "react-helmet"

const routes = require("../types/routes")

export default class Procurement extends React.Component {
  constructor(props) {
    super(props)
    this.routes = routes
  }

  render() {
    return (
      <LayoutDark>
        <Helmet>
          <title>Contingent: Procurement</title>
          <link rel="canonical" href={`${process.env.BASE_URL}`} />
          <meta name="title" content="Contingent: Procurement" />
          <meta
            name="description"
            content="Put an end to lengthy questionnaires, back-to-back emails
                      and long supplier onboarding."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`${process.env.BASE_URL}/`} />
          <meta property="og:title" content="Contingent: Procurement" />
          <meta
            property="og:description"
            content="Put an end to lengthy questionnaires, back-to-back emails
                      and long supplier onboarding."
          />
          <meta
            property="og:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={`${process.env.BASE_URL}/`} />
          <meta property="twitter:title" content="Contingent: Procurement" />
          <meta
            property="twitter:description"
            content="Put an end to lengthy questionnaires, back-to-back emails
                      and long supplier onboarding."
          />
          <meta
            property="twitter:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
        </Helmet>
        <div className={"section-container"}>
          <div className={"section-info basic detail"}>
            <div className={"container contact-us"}>
              <div className={"row"}>
                <div className={"column centered texture-2"}>
                  <div className={"content"}>
                    <h1 className={"title"}>Procurement</h1>
                    <p className={"description"}>
                      Put an end to lengthy questionnaires, back-to-back emails
                      and long supplier onboarding.
                    </p>
                    <div className={"images"}>
                      <object
                        data={"/assets/images/logos/bci-trans.svg"}
                        className={"card"}
                      ></object>
                      <object
                        data={"/assets/images/graphics/cips.png"}
                        className={"card"}
                      ></object>
                    </div>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column reversed"}>
                  <div className={"content"}>
                    <h2 className={"title"}>
                      Streamline the onboarding due diligence process
                    </h2>
                    <p className={"description"}>
                      There are many complex and manual parts in the supplier
                      onboarding process, due diligence process no longer has to
                      be one of them. With Contingent, you have data from
                      millions of suppliers at your fingertips empowering you to
                      search, assess and verify supplier risk data from one
                      platform in an instant.
                    </p>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                  <object
                    data={"/assets/images/graphics/procurement-1.svg"}
                    className={"card"}
                  ></object>
                </div>
              </div>
            </div>
          </div>
          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column"}>
                  <object
                    data={"/assets/images/graphics/procurement-3.svg"}
                    className={"card"}
                  ></object>
                  <div className={"content reversed"}>
                    <h2 className={"title"}>
                      Convert your reactive into proactive
                    </h2>
                    <p className={"description"}>
                      In our interconnected world, you can now visualise your
                      supply network from one dashboard, including those that
                      you don’t have a direct financial relationship with.
                      Switch from reacting to disruption to proactively managing
                      your supplier base to limit your risk exposure (i.e.
                      agent, distributor, donor, and joint venture partner).
                    </p>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column"}>
                  <object
                    data={"/assets/images/graphics/procurement-2.svg"}
                    className={"card"}
                  ></object>
                  <div className={"content reversed"}>
                    <h2 className={"title"}>
                      Quality data for accurate decision making
                    </h2>
                    <p className={"description"}>
                      We’re putting an end to making sourcing decisions based
                      solely on spend data. Over and above connecting
                      third-party data into one platform, Contingent provides
                      you with access to the actual sources of the data.
                    </p>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column reversed"}>
                  <div className={"content"}>
                    <h2 className={"title"}>Peace of mind</h2>
                    <p className={"description"}>
                      So many risk management processes have scheduled
                      questionnaires repeating each year, leaving you exposed in
                      the interval. We automate risk monitoring 24/7/365 and
                      send custom alerts for your critical requirements.
                    </p>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                  <object
                    data={"/assets/images/graphics/procurement-4.svg"}
                    className={"card"}
                  ></object>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Move towards proactive third-party risk management and
                    monitoring today.
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}
